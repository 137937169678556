import { INameDetails, IProfile } from 'utils/type'

export const formatWalletAddress = (
  address: string,
  firstDigitNumbers?: number,
  lastDigitNumbers?: number
) => {
  const numberOfLastDigits = lastDigitNumbers ?? 4
  if (!address) return ''

  const add1 = address.substring(0, firstDigitNumbers ?? 7)
  const add2 = address.substring(address.length - numberOfLastDigits)
  const finalAdd = `${add1}....${add2}`
  return finalAdd
}

export function trimString(str: string, maxLength: number, noAppend?: boolean) {
  if (!str) return ''
  if (str.length > maxLength) {
    return noAppend
      ? str.substring(0, maxLength)
      : str.substring(0, maxLength) + '...'
  } else {
    return str
  }
}

export function convertString(str: string): string {
  // Split the string by '-' to get individual words
  const words = str.split('-')

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )

  // Join the words with spaces
  const result = capitalizedWords.join(' ')

  return result
}

export function htmlDecode(input: string) {
  if (!input) return ''
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent ?? ''
}
/**
 * Returns the user's name based on the provided details.
 * If the display name is available, it is returned.
 * If not, the first name and last name are used to construct the name.
 * If neither is available, 'Unknown' is returned.
 *
 * @param details - The user's name details including first name, last name, and display name.
 * @returns The user's name.
 */
export const getUserName = (details: INameDetails) => {
  if (details?.display_name) {
    return details.display_name
  }
  if (details?.first_name || details?.last_name) {
    let firstName = details.first_name ?? ''
    let lastName = details.last_name ?? ''
    return firstName + (lastName ? ` ${lastName}` : '')
  }
  return 'Unknown'
}

export function renderCharactersFromAtoZ(): string[] {
  const startCharCode = 'A'.charCodeAt(0)
  const endCharCode = 'Z'.charCodeAt(0)

  const characters = Array.from(
    { length: endCharCode - startCharCode + 1 },
    (_, index) => String.fromCharCode(startCharCode + index)
  )

  return characters.map((character) => character)
}

export function extractUsername(url: string): string {
  if (!url) return ''
  const parts = url.split('/')
  const lastWord = '@' + parts[parts.length - 1]
  if (lastWord.length > 10) {
    return lastWord.substring(0, 10) + '..'
  }

  return lastWord
}

export const capitalizeFirstLetter = (sentence: string) => {
  if (!sentence) return ''

  const words = sentence?.split(/\s+/) // Use a regular expression to handle multiple spaces between words
  const capitalizedWords = words?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords?.join(' ')
}

export const transformString = (str: string) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/**
 * Validates the text input for an "Other" reason option.
 *
 * @param selectedOpt - The currently selected option.
 * @param otherText - The text input provided for the "Other" reason, if any.
 * @param otherReasonType - The specific option type that requires additional text input.
 * @returns A validation message indicating if the input is required or needs more detail.
 */
export const validateOtherReasonText = (
  selectedOpt: string,
  otherText: string | undefined,
  otherReasonType: string
): string => {
  let msg = ''

  if (selectedOpt === otherReasonType) {
    const otherTextLength = otherText?.length ?? 0

    if (otherTextLength === 0) {
      msg = 'Required'
    } else if (otherTextLength < 10) {
      msg = 'Please describe more'
    } else if (otherTextLength > 200) {
      msg = 'Text exceeds the maximum allowed length of 200 characters'
    }
  }

  return msg
}

export const getPublisherByEl = (
  publisherOptions: IProfile[],
  el: string
): INameDetails | undefined => {
  const data = publisherOptions?.find(
    (opt) => opt.id === el || opt.publisher_id === el
  )

  // Return undefined if no data is found
  if (!data) return undefined

  // Otherwise, return an object containing the relevant profile details
  return {
    last_name: data.last_name ?? '',
    first_name: data.first_name ?? '',
    display_name: data.display_name ?? '',
  }
}
