import { BoldText4 } from 'shared/typography'
import styled from 'styled-components'

interface ISpan {
  isDisable: boolean
}

export const CheckboxWrapper = styled.label<ISpan>`
  /* display: inline-block; */

  font-size: 14px;
  position: relative;
  padding-left: 28px;
  padding-bottom: 5px;
  padding-top: 4px;
  :hover {
    cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
  }
`
export const CheckboxWrapper2 = styled.label`
  font-size: 14px;
  display: flex;
  cursor: pointer;
  height: 24px;
  width: 24px;
`

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ .checkmark {
    background-color: ${(props) => props.theme.primary};
    border: none;
  }
  &:checked ~ .checkmark:after {
    display: block;
  }
`

export const Checkmark = styled.span<ISpan>`
  position: absolute;
  top: 4px;
  left: 0;

  height: 24px;
  width: 24px;
  border: 1px solid
    ${(props) =>
      props.isDisable ? props.theme.borderColor : props.theme.primaryText};
  border-radius: 6px;
  background-color: ${(props) =>
    props.isDisable ? props.theme.borderColor : props.theme.secondary};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 2px;
    width: 6px;
    height: 16px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  :hover {
    cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
  }
`

export const CheckboxLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 8px;
  ${BoldText4} {
    padding-top: 3px;
  }
`
export const Icon = styled.img`
  width: 24px;
  height: 24px;
`
