import styled from 'styled-components'
import { screenSizes } from 'styles/theme'
import { ITypoProps } from 'utils/type'

const Heading = styled.p<ITypoProps>`
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  max-width: ${(props) => props.maxWidth};
  text-wrap: ${(props) => props.textWrap};
  word-break: ${(props) => props?.wordBreak};
`

export const Text = styled.p<ITypoProps>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.2px;
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.textAlign};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => props.whiteSpace};
  font-style: ${(props) => props.fontStyle};
  overflow: ${(props) => props.overflow};
  text-overflow: ${(props) => props.textOverflow};
  opacity: ${(props) => props?.opacity};
  text-decoration: ${(props) => props?.textDecoration};
  text-wrap: ${(props) => props?.textWrap};
  word-break: ${(props) => props?.wordBreak};
  min-width: ${(props) => props?.minWidth};
`

export const Heading1 = styled(Heading)`
  font-size: 32px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
`
export const Heading2 = styled(Heading)`
  font-size: 30px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
`

export const Heading3 = styled(Heading)`
  font-size: 28px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  line-height: 30px;
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
`

export const Heading4 = styled(Heading)`
  font-size: 22px;
  line-height: 26px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight ?? '600'};
  text-align: ${(props) => props.textAlign};
`

export const Heading5 = styled(Heading)`
  font-size: 20px;
  line-height: 26px;
  text-transform: ${(props) => props.textTransform};
  color: ${(props) => props.color ?? props.theme.primaryText};
`

export const Heading8 = styled(Heading)`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
  text-align: ${(props) => props.textAlign};
`
export const Heading9 = styled(Heading)`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
  text-align: ${(props) => props.textAlign};
`

export const Heading10 = styled(Heading)`
  font-size: 96px;
  line-height: 115px;
  text-transform: ${(props) => props.textTransform};
  color: ${(props) => props.color ?? props.theme.primary};
`
export const Heading12 = styled(Heading)`
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
`

export const Heading13 = styled(Heading)`
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
`

export const Heading14 = styled(Heading)`
  font-size: 14px;
  opacity: ${(props) => props.opacity};
  line-height: 21px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight ?? 500};
  text-align: ${(props) => props.textAlign};
`

export const Text1 = styled(Text)`
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  opacity: ${(props) => props.opacity};
`
export const Text2 = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
  opacity: ${(props) => props.opacity};
`
export const Text3 = styled(Text)`
  font-size: 14px;
  opacity: ${(props) => props.opacity};
  line-height: 21px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
`
export const Text4 = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  opacity: ${(props) => props.opacity};
`
export const Text5 = styled(Text)`
  font-size: 11px;
  line-height: 16px;
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color ?? props.theme.primaryText};
  opacity: ${(props) => props.opacity};
`

export const Text6 = styled(Text)`
  font-size: 10px;
  line-height: 15px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  opacity: ${(props) => props.opacity};
`

export const Text7 = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.color};
`
export const Text8 = styled(Text)`
  font-size: 15px;
  opacity: ${(props) => props.opacity};
  line-height: ${(props) => props.lineHeight ?? '22px'};
  color: ${(props) => props.color ?? props.theme.primary};
`

export const Text9 = styled(Text)`
  font-size: 12px;
  color: ${(props) => props.color ?? props.theme.primaryText};
`

export const Text12 = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.color ?? props.theme.primaryText};
`

export const Text14 = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 400;
`
export const Text15 = styled(Text)`
  font-size: 8px;
  line-height: 15px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  opacity: ${(props) => props.opacity};
`

export const Text16 = styled(Text)`
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight};
  opacity: ${(props) => props.opacity};
`

export const BoldText3 = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: medium;
  opacity: ${(props) => props.opacity};
`
export const BoldText2 = styled(Text)`
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: normal;
`
export const BoldText4 = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
`
export const BoldText5 = styled(Text)`
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: medium;
  cursor: ${(props) => props.cursor};
`

export const BoldText6 = styled(Text)`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: medium;
`
export const BoldText7 = styled(Text)`
  font-size: 10px;
  line-height: 15px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: medium;
`
export const BoldText8 = styled(Text)`
  font-size: 18px;
  line-height: 27px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
`
export const BoldText9 = styled(Text)`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: medium;
`

export const BoldText10 = styled(Text)`
  font-size: 9px;
  line-height: 16px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  font-style: medium;
  opacity: ${(props) => props.opacity};
`
export const BoldText11 = styled(Text)`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
  font-style: medium;
`

export const BoldText12 = styled(Text)`
  font-size: 18px;
  line-height: 27px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
  font-style: medium;
`
export const BoldText13 = styled(Text)`
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
`
export const BoldText14 = styled(Text)`
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
  font-style: medium;
`

export const TableHeadText = styled(Text)`
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  white-space: nowrap;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 12px;
  }
`
export const BolderText1 = styled(Text)`
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: ${(props) => props.fontWeight ?? '700'};
  font-style: normal;
  text-align: ${(props) => props.textAlign};
  opacity: ${(props) => props.opacity};
`
export const BolderText2 = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  font-weight: ${(props) => props.fontWeight ?? '600'};
  color: ${(props) => props.color ?? props.theme.primaryText};
`

export const BolderText3 = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 700;
  font-style: normal;
`
export const BolderText4 = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 700;
  font-style: normal;
`

export const BolderText5 = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 700;
`
export const BolderText6 = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
  font-style: normal;
`

export const BoldText08 = styled(Text)`
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  opacity: ${(props) => props.opacity ?? '0.7'};
  color: ${(props) => props.color ?? props.theme.primaryText};
`

export const BolderText7 = styled(Text)`
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
  opacity: ${(props) => props.opacity};
  font-style: normal;
  width: ${(props) => props.width};
`
export const BolderText9 = styled(Text)`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
`
export const BolderText10 = styled(Text)`
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
`

export const BolderText11 = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ?? props.theme.primary};
  font-weight: 600;
`
export const BolderText13 = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 600;
`

export const Heading16 = styled(Heading)`
  font-size: 42px;
  line-height: 46px;
  text-transform: ${(props) => props.textTransform};
  color: ${(props) => props.color ?? props.theme.primary};
  font-weight: 700;
`

export const Heading17 = styled(Text)`
  font-weight: 700;
  font-size: 34px;
  line-height: 37px;

  color: ${(props) => props.color ?? props.theme.secondary};
`
