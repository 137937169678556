import { rgba } from 'polished'

export const maxWidth = 1800
export const headerHeight = 52
export const globalPadding = 40

interface Sizes {
  XXS: number | string
  XS: number | string
  S: number | string
  M: number | string
  ML?: number | string
  L: number | string
  XL: number | string
  XXL: number | string
}

export const gapSizes: Partial<Sizes> = {
  S: '10px',
  M: '20px',
  L: '30px',
  XL: '40px',
  XXL: '50px',
}

interface ScreenSizes {
  mediaXXS: number
  mediaXS: number
  mediaMS: number
  mediaSS: number
  mediaS: number
  mediaSM: number
  mediaM: number
  mediaMM: number
  mediaL: number
  mediaCL: number
  mediaXL: number
  mediaXCL: number
  mediaXXL: number
  mediaLX: number
  mediaLXX: number
}

export const screenSizes: ScreenSizes = {
  mediaXXS: 360,
  mediaXS: 380,
  mediaMS: 420,
  mediaSS: 480,
  mediaS: 640,
  mediaSM: 700,
  mediaM: 800,
  mediaMM: 900,
  mediaL: 1024,
  mediaCL: 1180,
  mediaXL: 1280,
  mediaXCL: 1350,
  mediaXXL: 1480,
  mediaLX: 1800,
  mediaLXX: 2000,
}

export const fontSizes = {
  mediaXS: '12px',
  mediaS: '14px',
  mediaM: '16px',
  mediaMM: '18px',
  mediaL: '24px',
  mediaXL: '36px',
  mediaXXL: '48px',
  headline1: '56px',
  headline2: '48px',
  headline3: '36px',
  headline4: '24px',
  display1: '72px',
  display2: '64px',
  display3: '48px',
  title1: '18px',
  title2: '16px',
  title3: '14px',
  labelLarge: '18px',
  labelMedium: '16px',
  labelSmall: '14px',
  labelExtraSmall: '12px',
  bodyLarge: '18px',
  bodyMedium: '16px',
  bodySmall: '14px',
  bodyExtraSmall: '12px',
}

interface ThemeWithStates {
  [propName: string]: string
}

interface Colors {
  amethyst: string
  amethystFaded: string
  black: string
  white: string
  offWhite: string
  blueMagentaViolet: string
  graniteGray: string
  graniteGrayLight: string
  red: string
  gray: string
  lightViolet: string
  darkViolet: string
  lightBlack: string
  lightGreen: string
  darkGreen: string
  lighterGray: string
  lightGray: string
  lightGray2: string
  grayWithOpacity: string
  raisinBlack: string
  disableGray: string
  lightishGray: string
  violetWithOpacity: string
  gray2: string
  darkBlue: string
  gray3: string
  jakarta: string
  lightRed: string
  cultured: string
  violet1: string
  blackWithOpacity: string
  lightGrey: string
  lightGrey2: string
  lighterGrey: string
  purpleWithOpacity: string
  gray4: string
  primaryGradient: string
  primaryGradient2: string
  green: string
  orange: string
  gray5: string
  gray6: string
  gray7: string
  maastrichtBlue: string
  black2: string
  lightRed4: string
  backgroundColor4: string
  gray8: string
  textBlack: string
  yellow: string
  skyBlue: string
  gray9: string
  gray10: string
  black3: string
  purple2: string
  secondary2: string
  charcoal: string
  lightSea: string
  darkGrey2: string
  greyish4: string
  emeraldGreen: string
  deepPlum: string
  pinkColor: string
  purpleColor: string
  darkGray: string
  brightGray: string
  tickColor: string
  lightGreenBackgroundColor: string
  forestGreen: string
  slateGray: string
  crimsonRed: string
  amberBrown: string
  mintGreen: string
  lightPeriwinkle: string
  palePink: string
  lightPeach: string
  charcoalGrey: string
  midnightPurple: string
  neonGreen: string
  slateGrey: string
}

export const colors: Colors = {
  amethyst: '#9163CC',
  white: '#FFFFFF',
  offWhite: '#f7f7f7',
  lightBlack: 'rgba(0, 0, 0, 0.1)',
  black: '#000000',
  blueMagentaViolet: '#5B2E95',
  graniteGray: '#292D32',
  graniteGrayLight: '#292d3255',
  red: '#E52A2A',
  gray: '#E7E7E7',
  lightViolet: '#6A4C90',
  darkViolet: '#312144',
  lightGreen: 'rgba(39, 174, 96, 0.1)',
  darkGreen: 'rgba(39, 174, 96, 1)',
  lighterGray: '#EDF2F6',
  lightGray: '#D3DBE2',
  grayWithOpacity: 'rgba(211, 219, 226, 0.6)',
  amethystFaded: '#9163cc1a',
  raisinBlack: '#232120',
  disableGray: 'rgba(39, 40, 43, 0.4)',
  lightishGray: '#919191',
  violetWithOpacity: 'rgba(145, 99, 204, 0.2)',
  gray2: '#E5E7EB',
  darkBlue: 'rgba(57, 74, 100, 1)',
  gray3: 'rgba(105, 106, 107, 1)',
  gray4: 'rgba(41, 45, 50, 0.2)',
  jakarta: '#3C2A53',
  lightRed: '#FFFEFD',
  cultured: '#F5F5F5',
  violet1: 'rgba(89, 58, 128, 1)',
  blackWithOpacity: 'rgba(0,0,0,0.55)',
  lightGrey: rgba(255, 255, 255, 0.5),
  lighterGrey: rgba(92, 92, 92, 1),
  purpleWithOpacity: rgba(145, 99, 204, 0.6),
  primaryGradient: 'linear-gradient(90deg, #A678DF 0%, #312144 283.45%)',
  primaryGradient2: 'linear-gradient(58.08deg, #2600FC 0%, #FF00EA 100%)',
  lightGrey2: '#5C5C5C',
  green: '#6FCF97',
  orange: '#F2994A',
  gray5: 'rgba(72, 76, 79, 1)',
  lightGray2: `#171B1E`,
  maastrichtBlue: 'rgb(8 38 47 / 54%)',
  gray6: rgba(97, 97, 97, 1),
  black2: '#27282B',
  gray7: '#F9F9F9',
  lightRed4: `rgba(229, 42, 42, 0.1)`,
  backgroundColor4: `rgba(231, 231, 231, 0.3)`,
  gray8: `rgba(90, 82, 96, 1)`,
  textBlack: 'rgba(43, 40, 46, 1)',
  yellow: '#F2C94C',
  skyBlue: '#56CCF2',
  gray9: '#878d96',
  gray10: 'rgba(217, 217, 217, 0.14)',
  purple2: 'rgba(145, 99, 204, 1)',
  black3: 'rgba(33, 12, 60, 1)',
  secondary2: '#5A5260',
  charcoal: `#5A5260`,
  lightSea: `#6fcf97`,
  darkGrey2: `#2B282E`,
  greyish4: `#78787C`,
  emeraldGreen: `#27AE60`,
  deepPlum: `#1F0732`,
  pinkColor: 'rgba(155, 81, 224, 1)',
  purpleColor: 'rgba(187, 107, 217, 0.2)',
  darkGray: `#717171`,
  brightGray: `#e9edf1`,
  tickColor: 'rgba(164, 164, 164, 0.2)',
  lightGreenBackgroundColor: `rgba(111, 207, 151, 0.15)`,
  forestGreen: `#14804A`,
  slateGray: `#5A6376`,
  crimsonRed: `#D1293D`,
  amberBrown: `#AA5B00`,
  mintGreen: `#E1FCEF`,
  lightPeriwinkle: `#E9EDF5`,
  palePink: `#FFEDEF`,
  lightPeach: `#FCF2E6`,
  charcoalGrey: `#2A2A30`,
  midnightPurple: `#120823`,
  neonGreen: `#20F986`,
  slateGrey: `#687182`,
}

export interface Theme {
  [propName: string]:
    | string
    | ThemeWithStates
    | { [propName: string]: ThemeWithStates }
    | undefined
  primary: string
  secondary: string
  primaryText: string
  secondaryText: string
  hyperLink: string
  error: string
  icon: string
  borderColor: string
  inputBorderColor: string
  cardBackground: string
  cardBackground2: string
  cardBackground3: string
  cardShadow: string
  liveColor: string
  liveText: string
  priceTitle: string
  priceDescription: string
  feature: string
  primaryFaded: string
  dateText: string
  disableColor: string
  lightishGray: string
  inputBoxShadow: string
  dividerColor: string
  orColor: string
  textColor3: string
  footer: string
  errorBackground: string
  modalBg: string
  homeHeading: string
  successBackground: string
  lightGrey: string
  lightGrey2: string
  textColor: string
  rightBorderColor: string
  linearBackgroundPrimary: string
  borderLight: string
  primaryGradient: string
  primaryGradient2: string
  checkBoxBackground: string
  green: string
  text10: string
  lightGrey3: string
  maastrichtBlue: string
  gray6: string
  black2: string
  gray7: string
  errorBackground2: string
  reviewBackground: string
  reviewBy: string
  textBlack: string
  tabActive: string
  tabInactive: string
  tableHeader: string
  tableHeaderText: string
  tableHeaderText2: string
  tableRowText: string
  primaryText2: string
  newGameText: string
  gameDetailsText: string
  whiteBg: string
  chatTextColor: string
  tagTextColor: string
  pageBgColor: string
  parentStatusColor: string
  parentStatusBackgroundColor: string
  inputLabel: string
  rangeListBorder: string
  tickColor: string
  newGameBackgroundColor: string
  darkGray: string
  charcoal: string
  darkGrey2: string
  eduLive: string
  eduDraft: string
  eduEnded: string
  eduUpcoming: string
  eduBgLive: string
  eduBgDraft: string
  eduBgEnded: string
  eduBgUpcoming: string
  eduSubText: string
  eduPageBg: string
  darkText: string
  eduDotBg: string
  brightGray: string
}
// Common theme properties that are shared across different themes.
const commonThemeProperties = {
  primary: colors.amethyst,
  secondary: colors.white,
  primaryFaded: colors.amethystFaded,
  icon: colors.graniteGray,
  primaryText: colors.black,
  secondaryText: colors.white,
  hyperLink: colors.blueMagentaViolet,
  error: colors.red,
  borderColor: colors.gray,
  inputBorderColor: colors.graniteGrayLight,
  cardShadow: colors.lightBlack,
  liveColor: colors.lightGreen,
  liveText: colors.darkGreen,
  priceTitle: colors.lighterGray,
  priceDescription: colors.lightGray,
  feature: colors.grayWithOpacity,
  dateText: colors.raisinBlack,
  disableColor: colors.disableGray,
  lightishGray: colors.lightishGray,
  inputBoxShadow: colors.violetWithOpacity,
  dividerColor: colors.gray2,
  orColor: colors.darkBlue,
  textColor3: colors.gray3,
  footer: colors.jakarta,
  errorBackground: colors.lightRed,
  modalBg: colors.white,
  lightGrey: colors.lightGrey,
  lightGrey2: colors.lightGrey2,
  green: colors.green,
  text10: colors.gray5,
  lightGrey3: colors.lightGray2,
  maastrichtBlue: colors.maastrichtBlue,
  gray6: colors.gray6,
  gray7: colors.gray7,
  black2: colors.black2,
  errorBackground2: colors.lightRed4,
  reviewBackground: colors.backgroundColor4,
  reviewBy: colors.gray8,
  textBlack: colors.textBlack,
  emeraldGreen: colors.emeraldGreen,
  darkGrey2: colors.darkGrey2,
  deepPlum: colors.deepPlum,
  pinkColor: colors.pinkColor,
  purpleColor: colors.purpleColor,
  darkGray: colors.darkGray,
  brightGray: colors.brightGray,
  tickColor: 'rgba(164, 164, 164, 0.2)',
  primaryGradient: colors.primaryGradient,
  primaryGradient2: colors.primaryGradient2,
  charcoal: colors.charcoal,
  eduLive: colors.forestGreen,
  eduDraft: colors.slateGray,
  eduEnded: colors.crimsonRed,
  eduUpcoming: colors.amberBrown,
  eduBgLive: colors.mintGreen,
  eduBgDraft: colors.lightPeriwinkle,
  eduBgEnded: colors.palePink,
  eduBgUpcoming: colors.lightPeach,
  eduSubText: colors.charcoalGrey,
  eduPageBg: colors.midnightPurple,
  darkText: colors.neonGreen,
  eduDotBg: colors.slateGrey,
}

// Additional properties specific to each theme.
export const basicTheme = {
  ...commonThemeProperties,
  cardBackground: `linear-gradient(180deg,${colors.lightViolet}, ${colors.darkViolet})`,
  cardBackground2: `linear-gradient(180deg,${colors.offWhite} 0%, ${colors.white}) 100%`,
  cardBackground3: `linear-gradient(180deg, ${colors.offWhite} 0%, ${colors.white} 100%)`,
  linearBackgroundPrimary: `linear-gradient(${colors.violetWithOpacity}, ${colors.amethyst})`,
}

export const lightTheme = {
  ...commonThemeProperties,
  cardBackground: `linear-gradient(${colors.lightViolet}, ${colors.darkViolet})`,
  cardBackground2: `linear-gradient(${colors.offWhite}, ${colors.white})`,
  cardBackground3: `linear-gradient(180deg, ${colors.offWhite} 0%, ${colors.white} 100%)`,
  linearBackgroundPrimary: `linear-gradient(90deg, rgba(145,99,204,0.5) 15%, rgba(145,99,204,0.2) 100%)`,
}

export enum Themes {
  BASIC,
  LIGHT,
}

export const getTheme = (theme: Themes) => {
  switch (theme) {
    case Themes.BASIC:
      return basicTheme
    case Themes.LIGHT:
      return lightTheme
    default:
      return basicTheme
  }
}
